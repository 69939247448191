.adyen-checkout-ctp__section {
  position: relative;
  background-color: white;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 16px; }
  .adyen-checkout-ctp__section-brand {
    display: flex;
    align-items: center;
    height: 18px;
    margin-bottom: 14px; }
  .adyen-checkout-ctp__section .adyen-checkout__fieldset {
    margin-bottom: 24px; }

.adyen-checkout-ctp__section-header {
  display: flex;
  align-items: center; }
  .adyen-checkout-ctp__section-header-title {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 0 4px;
    padding: 0px;
    width: auto; }
    @media screen and (max-width: 400px) {
      .adyen-checkout-ctp__section-header-title {
        font-size: 15px; } }
  .adyen-checkout-ctp__section-header-adornment {
    margin-left: 5px; }

.adyen-checkout-ctp__section-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  color: #687282;
  margin: 0 0 16px; }

.adyen-checkout-ctp__separator {
  color: #00112c;
  font-size: 13px;
  font-weight: 400; }
